import React, { useEffect, useState } from 'react'
import cx from 'classnames'

import WizardSlider from 'piconetworks/pkg-wizard-slider'
import Loading from 'piconetworks/pkg-loading'

import Loader from 'modules/loader'
import Gift from 'modules/gift'
import LandingPage from 'modules/landing-page'
import Client from 'modules/client'
import Plan from 'modules/plan'
import Purchase from 'modules/purchase'
import Products from 'modules/products'

import Markdown from 'wrappers/Markdown'
import orderTypes from './orderTypes'
import styles from './CartHelper.module.scss'

import { useRouter } from 'lib/RouterAdapterDecorator'
import { typeMap } from './getFields'
import FeatureFlags from 'modules/feature-flags'
import { useSelector } from 'react-redux'

const getOrderType = ({
    product,
    userHasCurrentPurchases,
    isGift,
    isConvert,
    option: _option,
}) => {
    const isPageConsolidation = useSelector((state) => FeatureFlags.selectors.getFeatureFlagIsEnabled(state, 'page-consolidation'))

    let productType = null

    const option = _option || (product?.options[0] || {})

    const { type } = option

    if (type === 'subscription') {
        const isPledgeMode = product.pledge_mode
        const isTrial = (
            (!!option.trial_price || option.trial_price === null)
            && !!option.trial_interval
            && !!option.trial_quantity
        )

        if (isPledgeMode) {
            productType = 'pledge'
        } else if (isTrial) {
            productType = 'trial'
        } else {
            productType = type
        }
    } else {
        productType = type
    }

    if (isConvert) {
        return orderTypes.gift.existing.convert
    }

    switch (productType) {
        case 'pass':
            if (isGift) {
                return orderTypes.gift.new.regular
            }
            return orderTypes.pass.new.regular

        case 'subscription':
            if (isGift) {
                return orderTypes.gift.new.regular
            }
            if (userHasCurrentPurchases) {
                return orderTypes.subscription.existing.switch
            }
            return orderTypes.subscription.new.regular

        case 'pledge':
            if (isPageConsolidation) {
                if (isGift) {
                    return orderTypes.gift.new.regular
                }
                if (userHasCurrentPurchases) {
                    return orderTypes.subscription.existing.switch
                }
                return orderTypes.subscription.new.regular
            } else {
                if (isGift) {
                    return orderTypes.gift.new.pledge
                }
                if (userHasCurrentPurchases) {
                    return orderTypes.subscription.existing.switch
                }
                return orderTypes.subscription.new.pledge
            }
        case 'trial':
            if (isGift) {
                return orderTypes.gift.new.regular
            }
            if (userHasCurrentPurchases) {
                return orderTypes.subscription.existing.switch
            }
            return orderTypes.subscription.new.trial

        case 'single-donation':
        case 'recurring-donation':
            if (userHasCurrentPurchases) {
                return orderTypes.donation.existing.switch
            }
            return orderTypes.donation.new.recurring

        case 'group':
            return orderTypes.group.new.regular

        default:
        // do nothing
    }

    return null
}

const GetSelectOrder = ({
    state,
    dispatch,
}) => {
    const router = useRouter()
    const wizardName = router?.query?.id
    const isGift = wizardName?.includes('GiftWizard')
    const shortCode = router?.query?.short_code
    const landingPage = LandingPage.selectors.getLandingPageByShortCode(state, shortCode, 'payment')

    const products = landingPage?.products || []
    const currentPurchaseActive = Loader.selectors.currentPurchaseActive(state)
    const userHasCurrentPurchases = Loader.selectors.userHasCurrentPurchases(state)
    const currentPurchaseProductId = userHasCurrentPurchases
        && state?.loader?.user?.purchases?.current?.product_id
    const currentPurchaseOptionId = userHasCurrentPurchases
        && state?.loader?.user?.purchases?.current?.product_option_id

    const wizardData = state.wizard.wizards[wizardName]
    const wizardPayload = wizardData?.submit?.payload || {}

    const isGroup = landingPage?.type === 'group_landing_page'
    const isSwitch = wizardPayload?.isSwitch || currentPurchaseActive
    const groupIsPaid = isGroup && wizardPayload?.isPaying && wizardData?.currentStep
    const isOfferCodeActive = wizardPayload?.isOfferCodeActive

    const switchableProducts = Purchase.selectors.getSwitchableProducts(state)
    const switchableProductsIds = switchableProducts.map(({ id }) => id)
    const getSwitchProductsLoading = Purchase.selectors.getSwitchProductsLoading(state)
    const isGroupMember = Loader.selectors.isGroupMember(state)

    // logger.info(products)

    // depending on current context, the way products are filtered varies
    const filterMethods = {
        switch: (id, type) => switchableProductsIds.includes(id) || isOfferCodeActive || type === 'donation',
        group: (type) => type === 'donation' || wizardPayload?.isPaying,
        gift: () => true,
        default: (id) => id !== currentPurchaseProductId,
    }

    const filteredProducts = products.filter(({ id, type }) => {
        let filterResult = filterMethods.default(id)
        if (isSwitch) {
            filterResult = filterMethods.switch(id, type)
        }
        if (isGroupMember) {
            filterResult = filterMethods.group(type)
        }
        if (isGift) {
            filterResult = filterMethods.gift()
        }
        return filterResult
    })

    // logger.info(filteredProducts)

    const publisherId = Client.selectors.id(state)

    useEffect(() => {
        if (isSwitch && currentPurchaseProductId) {
            dispatch(Purchase.creators.getSwitchProducts({
                payload: {
                    productId: currentPurchaseProductId,
                    publisherId,
                },
            }))
        }
    }, [currentPurchaseProductId, dispatch, isSwitch, publisherId])

    if (getSwitchProductsLoading) {
        return {
            Selection: () => (
                <Loading className={styles.loading} />
            ),
        }
    }

    if (!filteredProducts.length) {
        return {
            Selection: () => (
                <div className={cx(styles.contentContainer, 'text-center')}>
                    <WizardSlider
                        title="Cannot Proceed"
                        subtitle="There are no eligible products associated to this offer."
                    >
                        <i
                            className="icon-icon_warning"
                            style={{
                                filter: 'grayscale(1)',
                                fontSize: '9rem',
                            }}
                        />
                    </WizardSlider>
                </div>
            ),
        }
    }

    const mappedProducts = filteredProducts.map((product) => {
        const productType = product.options
            .reduce((typesArray, option) => {
                const types = typesArray[0]
                const { type } = option

                if (type === 'subscription') {
                    const isPledgeMode = product.pledge_mode
                    const isTrial = (
                        (!!option.trial_price || option.trial_price === null)
                        && !!option.trial_interval
                        && !!option.trial_quantity
                    )

                    if (isPledgeMode) {
                        types.pledge = null
                    } else if (isTrial) {
                        types.trial = null
                    } else {
                        types[type] = null
                    }
                } else if (type?.includes('-donation')) {
                    types.donation = null
                } else {
                    types[type] = null
                }

                return [types]
            }, [{}])
            .reduce((result, types) => Object.keys(types)[0], null)

        switch (productType) {
            case 'pass':
                return {
                    product,
                    ...orderTypes.pass.new.regular,
                }

            case 'subscription':
                return {
                    product,
                    ...orderTypes.subscription.new.regular,
                }

            case 'pledge':
                return {
                    product,
                    ...orderTypes.subscription.new.pledge,
                }

            case 'trial':
                return {
                    product,
                    ...orderTypes.subscription.new.trial,
                }

            case 'donation':
                return {
                    product,
                    ...orderTypes.donation.new.regular,
                }

            case 'group':
                return {
                    product,
                    ...orderTypes.group.new.regular,
                }

            default:
            // do nothing
        }

        return {}
    })

    const Selections = (props) => mappedProducts.map(({ product, Selection }) => {
        if (!Selection) {
            return null
        }

        const richTextUrl = product?.rich_text_url

        return (
            <>
                <Selection
                    {...props}
                    key={product.id}
                    product={product}
                    isGift={isGift}
                    currentPurchaseOptionId={currentPurchaseOptionId}
                    groupIsPaid={groupIsPaid}
                    isSwitch={isSwitch}
                />
                {richTextUrl && (
                    <Markdown url={richTextUrl} />
                )}
            </>
        )
    })

    return {
        Selection: (props) => (
            <>
                {Selections(props)}
            </>
        ),
    }
}

const SelectOrder = ({
    product: _product,
}) => {
    const SelectSubscription = typeMap.SelectSubscription
    const richTextUrl = _product?.rich_text_url

    return (
        <>
            <SelectSubscription
                name={'productOptionId'}
                required={false}
                product={_product}
                isGift={false}
            />
            {richTextUrl && (
                <Markdown url={richTextUrl} />
            )}
        </>
    )
}

const GetConfirmPaymentData = ({
    state,
    wizardName = '',
    dispatch,
}) => {
    const isConvert = wizardName.includes('ConvertGiftWizard')
    const isGift = wizardName.includes('GiftWizard') && !isConvert

    const userHasCurrentPurchases = Loader.selectors.userHasCurrentPurchases(state)

    // find the chosen product and plan in the wizard data
    const wizardData = state.wizard.wizards[wizardName]

    const wizardPayload = wizardData?.submit?.payload || {}

    const {
        optionId,
    } = wizardPayload

    const customPrice = wizardPayload?.customPrice || wizardPayload?.custom_price

    const product = Products.selectors.getProductByProductOptionId(state, optionId)
        || LandingPage.selectors.landingPageGetProductByPlanOptionId(state, optionId)
    const option = Products.selectors.getProductOptionById(state, optionId)
        || LandingPage.selectors.landingPageGetOptionById(state, optionId)
    const popup = LandingPage.selectors.getLandingPageByPlanOptionId(state, optionId)

    // get the appropriate UI and data for the plan in question
    const {
        type,
        Summary,
        postDataAction = () => { },
    } = getOrderType({
        product,
        option,
        userHasCurrentPurchases,
        isGift,
        isConvert,
    }) || {}

    // set up the switch now checks

    const publisherId = Client.selectors.id(state)
    const switchNow = Plan.selectors.switchNow(state)

    useEffect(() => {
        if (type === orderTypes.subscription.existing.switch.type
            || type === orderTypes.donation.existing.switch.type
        ) {
            dispatch(Plan.creators.checkCanSwitchPlanNow({
                productOptionId: optionId,
                publisherId,
            }))
        }
    }, [
        dispatch,
        type,
        publisherId,
        optionId,
    ])

    // create switch now interface
    const [switchNowStatus, changeSwitchNowStatus] = useState(true)

    const switchNowComponentData = {
        ...(switchNow && {
            ...switchNow,
            switchNowStatus,
            changeSwitchNowStatus,
        }),
    }

    const switchNowPostData = {
        ...(switchNow && {
            payNow: switchNowStatus,
        }),
    }

    // if converting a gift, fetch the gift id
    const giftId = Gift.selectors.giftId(state)

    useEffect(() => {
        if (type === orderTypes.gift.existing.convert.type) {
            dispatch(Gift.creators.getGift({ publisherId }))
        }
    }, [
        dispatch,
        publisherId,
        type,
    ])

    // return the bundle
    return {
        product,
        option,
        type,
        customPrice,
        Summary: () => (
            <Summary
                product={product}
                option={option}
                type={type}
                customPrice={customPrice}
                state={state}
                switchNow={switchNowComponentData}
                wizardPayload={wizardPayload}
            />
        ),
        postDataAction: () => postDataAction({
            product,
            option,
            type,
            customPrice,
            state,
            popup,
            publisherId,
            ...switchNowPostData,
            ...(giftId && { giftId }),
            wizardPayload,
        }),
    }
}

export {
    getOrderType,
    GetConfirmPaymentData,
    GetSelectOrder,
    SelectOrder,
}
