import React from 'react'
import PropTypes from 'prop-types'

import Client from 'modules/client'
import Gift from 'modules/gift'

import getFields from '../getFields'

const type = 'gift.new.pledge'

const summaryFields = getFields([
    {
        id: 'productOptionId',
        type: 'GiftNewPledge',
        required: true,
    },
])

const Summary = (props = {}) => summaryFields.map((Field) => (
    <Field
        key={props.id}
        {...props}
    />
))

const postDataAction = ({
    state,
    option,
    product,
    wizardPayload,
    popup,
}) => ({
    action: Gift.creators.createGift,
    payload: {
        publisherId: Client.selectors.id(state),
        formId: popup?.id,
        product_option_id: option?.id,
        tier_id: product.id,
        company_slug: `@${Client.selectors.username(state)}`,
        note: wizardPayload.note,
        recipient: wizardPayload.recipient,
        sender: wizardPayload.sender,
    },
})

Summary.propTypes = {
    state: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
}

export default {
    type,
    Summary,
    postDataAction,
}
