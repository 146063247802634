import React from 'react'
import PropTypes from 'prop-types'

import Client from 'modules/client'
import Plan from 'modules/plan'

import getFields from '../getFields'

const type = 'group.new.regular'

const postDataAction = ({
    state,
    option,
    popup,
}) => ({
    action: Plan.creators.purchasePlan,
    payload: {
        formId: popup?.id,
        productOptionId: option?.id,
        publisherId: Client.selectors.id(state),
    },
})

const fields = getFields([
    {
        id: 'productOptionId',
        type: 'SelectGroup',
        required: true,
    },
])

const Selection = (props = {}) => fields.map((Field) => (
    <Field
        key={props.id}
        {...props}
    />
))

const summaryFields = getFields([
    {
        id: 'productOptionId',
        type: 'GroupNewRegular',
        required: true,
    },
])

const Summary = (props = {}) => summaryFields.map((Field) => (
    <Field
        key={props.id}
        {...props}
    />
))

Summary.propTypes = {
    state: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
}

export default {
    type,
    Selection,
    Summary,
    postDataAction,
}
