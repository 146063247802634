import React from 'react'
import PropTypes from 'prop-types'

import Client from 'modules/client'
import Gift from 'modules/gift'

import getFields from '../getFields'

const type = 'gift.new.regular'

const fields = getFields([
    {
        id: 'productOptionId',
        type: 'SelectPass',
        required: true,
    },
])

const summaryFields = getFields([
    {
        id: 'productOptionId',
        type: 'GiftNewRegular',
        required: true,
    },
])

const Summary = (props = {}) => summaryFields.map((Field) => (
    <Field
        key={props.id}
        {...props}
    />
))

const Selection = (props = {}) => fields.map((Field) => (
    <Field
        key={props.id}
        isGift
        {...props}
    />
))

const postDataAction = ({
    state,
    option,
    product,
    wizardPayload,
    popup,
}) => ({
    action: Gift.creators.createGift,
    payload: {
        formId: popup.id,
        publisherId: Client.selectors.id(state),
        product_option_id: option.id,
        tier_id: product.id,
        company_slug: `@${Client.selectors.username(state)}`,
        note: wizardPayload.note,
        recipient: wizardPayload.recipient,
        sender: wizardPayload.sender,
    },
})

Summary.propTypes = {
    state: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
}

export default {
    type,
    Summary,
    postDataAction,
    Selection,
}
