import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Client from 'modules/client'
import Router from 'lib/RouterAdapterDecorator'

import { RemoteMarkdown } from 'piconetworks/pkg-remote-markdown'

const Markdown = ({ url, className }) => {
    const linkColor = useSelector(Client.selectors.linkColor)

    return (
        <RemoteMarkdown
            className={className}
            url={url}
            onClickLink={(href) => href && window.open(href, '_blank', 'noopener', 'noreferrer')}
            linkColor={linkColor}
            options={{
                breaks: true,
            }}
        />
    )
}

Markdown.defaultProps = {
    url: '',
    className: '',
}

Markdown.propTypes = {
    url: PropTypes.string,
    className: PropTypes.string,
}

export default Markdown
