import React from 'react'
import PropTypes from 'prop-types'

import Client from 'modules/client'
import Plan from 'modules/plan'

import getFields from '../getFields'

const type = 'pass.new.regular'

const selectionFields = getFields([
    {
        id: 'productOptionId',
        type: 'SelectPass',
        required: true,
    },
])

const Selection = (props = {}) => selectionFields.map((Field) => (
    <Field
        key={props.id}
        {...props}
    />
))

const summaryFields = getFields([
    {
        id: 'productOptionId',
        type: 'PassNewRegular',
        required: true,
    },
])

const Summary = (props = {}) => summaryFields.map((Field) => (
    <Field
        key={props.id}
        {...props}
    />
))

const postDataAction = ({
    state,
    option,
    popup,
}) => ({
    action: Plan.creators.purchasePlan,
    payload: {
        formId: popup.id,
        productOptionId: option.id,
        publisherId: Client.selectors.id(state),
        // ...(discountCodeId && { discountCodeId }),
    },
})

Summary.propTypes = {
    state: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
}

export default {
    Selection,
    Summary,
    postDataAction,
    type,
}
