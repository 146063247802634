import React from 'react'
import PropTypes from 'prop-types'

import Client from 'modules/client'
import Plan from 'modules/plan'

import getFields from '../getFields'

const type = 'subscription.existing.switch'

const summaryFields = getFields([
    {
        id: 'productOptionId',
        type: 'SubscriptionExistingSwitch',
        required: true,
    },
])

const Summary = (props = {}) => summaryFields.map((Field) => (
    <Field
        key={props.id}
        {...props}
    />
))

const postDataAction = ({
    state,
    option,
    customPrice,
    payNow,
    popup,
}) => ({
    action: Plan.creators.purchaseSwitch,
    payload: {
        formId: popup?.id,
        productOptionId: option?.id,
        publisherId: Client.selectors.id(state),
        ...(customPrice && { customPrice }),
        pay_now: payNow,
        // ...(discountCodeId && { discountCodeId }),
    },
})

Summary.defaultProps = {
    switchNow: null,
}

Summary.propTypes = {
    state: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    switchNow: PropTypes.bool,
}

export default {
    type,
    Summary,
    postDataAction,
}
