import GiftExistingConvert from './components/gift.existing.convert'
import SubscriptionNewRegular from './components/subscription.new.regular'
import SubscriptionNewPledge from './components/subscription.new.pledge'
import SubscriptionExistingSwitch from './components/subscription.existing.switch'
import PassNewRegular from './components/pass.new.regular'
import DonationNewRecurring from './components/donation.new.recurring'
import DonationNewRegular from './components/donation.new.regular'
import DonationNewOneTime from './components/donation.new.oneTime'
import GiftNewRegular from './components/gift.new.regular'
import GiftNewPledge from './components/gift.new.pledge'
import GroupNewRegular from './components/group.new.regular'
import DonationExistingSwitch from './components/donation.existing.switch'
import SubscriptionNewTrial from './components/subscription.new.trial'

const orderTypes = {
    group: {
        new: {
            regular: GroupNewRegular,
        },
    },
    donation: {
        new: {
            oneTime: DonationNewOneTime,
            recurring: DonationNewRecurring,
            regular: DonationNewRegular,
        },
        existing: {
            switch: DonationExistingSwitch,
        },
    },
    gift: {
        new: {
            regular: GiftNewRegular,
            pledge: GiftNewPledge,
        },
        existing: {
            convert: GiftExistingConvert,
        },
    },
    pass: {
        new: {
            regular: PassNewRegular,
        },
    },
    subscription: {
        new: {
            regular: SubscriptionNewRegular,
            pledge: SubscriptionNewPledge,
            trial: SubscriptionNewTrial,
        },
        existing: {
            switch: SubscriptionExistingSwitch,
        },
    },
}

export default orderTypes
