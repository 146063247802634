import React from 'react'
import PropTypes from 'prop-types'

import Client from 'modules/client'
import Plan from 'modules/plan'

import getFields from '../getFields'

const fields = getFields([
    {
        id: 'productOptionId',
        type: 'SelectTrial',
        required: true,
    },
])

const Selection = (props = {}) => fields.map((Field) => (
    <Field
        key={props.id}
        {...props}
    />
))

const type = 'subscription.new.trial'

const summaryFields = getFields([
    {
        id: 'productOptionId',
        type: 'SubscriptionNewPledge',
        required: true,
    },
])

const Summary = (props = {}) => summaryFields.map((Field) => (
    <Field
        key={props.id}
        {...props}
    />
))

const postDataAction = ({
    state,
    option,
    customPrice,
    popup,
}) => ({
    action: Plan.creators.purchasePlan,
    payload: {
        formId: popup?.id,
        productOptionId: option?.id,
        publisherId: Client.selectors.id(state),
        ...(customPrice && { customPrice }),
        // ...(discountCodeId && { discountCodeId }),
    },
})

Summary.defaultProps = {
    customPrice: null,
}

Summary.propTypes = {
    state: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    customPrice: PropTypes.string,
}

export default {
    type,
    Selection,
    Summary,
    postDataAction,
}
