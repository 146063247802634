import React from 'react'
import PropTypes from 'prop-types'

import Gift from 'modules/gift'

import getFields from '../getFields'

const type = 'gift.existing.convert'

const summaryFields = getFields([
    {
        id: 'productOptionId',
        type: 'GiftExistingConvert',
        required: true,
    },
])

const Summary = (props = {}) => summaryFields.map((Field) => (
    <Field
        key={props.id}
        {...props}
    />
))

const postDataAction = ({
    giftId,
    publisherId,
}) => ({
    action: Gift.creators.convertGift,
    payload: {
        giftId,
        publisherId,
    },
})

Summary.propTypes = {
    state: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
}

export default {
    type,
    Summary,
    postDataAction,
}
